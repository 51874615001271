import React from "react"
import tw, { styled } from "twin.macro"


const ContentBlockHeaderStyle = styled.div`
  ${tw`bg-apana-pink bg-opacity-10 py-6 font-basker text-apana-plum max-w-screen-xl m-auto flex flex-col md:flex-row justify-between`}
  h1 { ${tw`text-5xl pb-3`} }
  h2 { ${tw`text-4xl pb-2 leading-snug`} }
  h3 { ${tw`text-3xl pb-2`} }
  h4 { ${tw`font-sans font-medium tracking-wider uppercase`} }
  .layout-contain { ${tw`pl-3 flex flex-col justify-center`} }
`

const ContactContainer = tw.div`flex`;


const ContentBlockHeader = (props) => (
  <ContentBlockHeaderStyle>
    <div className='layout-contain'>
      <h1>Apana Yoga</h1>
      <h2>Reflect, inside &amp; out</h2>
      <p>Premium quality Yoga Apparel and nothing less</p>
    </div>
    <ContactContainer>
      { props.children }
    </ContactContainer>
  </ContentBlockHeaderStyle>
)

export default ContentBlockHeader
