import React from "react"
import tw, { styled } from "twin.macro"


const ProductStyle = styled.div`
  ${tw`max-w-screen-xl mx-auto w-full grid row-gap-4 col-gap-4 bg-apana-light bg-opacity-30 grid-cols-2 lg:grid-cols-5 py-4 md:p-4 my-2`}
  a {${tw`m-0`}}
`

const AmazonBlockStyle = styled.div`

  ${tw`text-left font-basker antialiased text-apana-jungle`}

  img.hover-alternate {
    display: none;
  }
  &:hover {
    img.main-image {
      display: none;
    }
    img.hover-alternate {
      display:block;
    }
    button {
      ${tw`text-apana-jungle`}
    }
  }

  img {
    ${tw`w-full`}
  }

  h1 {
    ${tw`text-2xl mt-5 mb-6 text-apana-plum`}
  }

  h2 {
    ${tw`text-xl`}
  }

  button {
    ${tw`text-apana-wine text-left`}
  }

  .info {
    ${tw`flex flex-col justify-between px-4 md:p-0 space-y-2 flex-grow`}

  }

`

const AmazonBlock = ({name, src, asin, hover, description}) =>
  <AmazonBlockStyle>
      <a href={`https://amazon.com/dp/${asin}`} >
        <img className="main-image" alt={name} src={src} />
        <img className="hover-alternate" alt={`${name}-alternate`} src={hover} />
        <div className='info'>
          <div>
            <h1>{name}</h1>
            <p>{description}</p>
          </div>
          <button>Shop Now</button>
        </div>
      </a>
  </AmazonBlockStyle>

const ProductStrip = () =>
  <ProductStyle>

    <AmazonBlock hover="https://adjafiles.com/apana/apana-ponte-short-back.jpg" src="https://adjafiles.com/apana/apana-ponte-short-front.jpg" name="Men's Ponte Soft Touch Yoga Shorts" asin="B09RQYT2Q7" description={`Our customer favorite soft touch ponte weave fabric now available on a short`} />

    <AmazonBlock hover="https://adjafiles.com/apana/2-APANA_AMAZON_ONMODEL-195.jpg" src="https://adjafiles.com/apana/4-APANA_AMAZON_ONMODEL-196.jpg" name="Men's Lightweight Stetch Yoga Hoodie Pullover" asin="B09J9XTBMB" description={`Perfect work to yoga hoodie. Just enough stretch for a clean fit without compromising range of motion`} />

    <AmazonBlock hover="https://adjafiles.com/apana/apana-womens-hoodie-back.jpg" src="https://adjafiles.com/apana/apana-womens-hoodie-front.jpg" name="Women's Active-Stretch Long Sleeve Hoody" asin="B099X7B15K" description={`Spandex components make for a clean fit with the full range of motion needed for your yoga workouts.`} />

    <AmazonBlock hover="https://adjafiles.com/apana/pants-action.jpg" src="https://adjafiles.com/apana/pants-front.jpg" name='Slim Fit Athletic Yoga Lounge Pants with Pockets' asin="B09JBLLC4G" description="The yoga pants are designed to fit with a slim profile without compromising range of motion" />

    <AmazonBlock hover="https://adjafiles.com/apana/shirt-action.jpg" src="https://adjafiles.com/apana/shirt.jpg" name="Women's Active Top Short Sleeve Workout Shirt" asin="B092DY5NBL" description={`Clean design with and sweat-wicking performance fabric ensures comfort during your toughest hottest workouts.`} />

  </ProductStyle>


export default ProductStrip
