import React from "react"
import tw, { styled } from "twin.macro"
import logo from "../images/logo.svg"

const VidBoxLogo = tw.img`w-24 block`

const InstaBlock = () => {

  const InstaBlockWrap = styled.div`${tw`border-black p-5 text-center`}`

  return (
    <InstaBlockWrap>

      <div tw='hover:scale-105 transform duration-300 shadow-sm hover:shadow-xl rounded-full w-48 h-48 bg-apana-wine flex flex-col justify-center items-center mx-auto mt-6 mb-12'>
        <h2 tw='pt-6 pb-3 text-white text-sm'>
          FOLLOW
        </h2>
        <a tw='mb-7 inline-block' href="https://instagram.com/apana_yoga">
          <VidBoxLogo src={logo} />
        </a>
      </div>
    </InstaBlockWrap>
  )

}



export default InstaBlock;
