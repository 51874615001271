import React from "react"
import tw, { styled } from "twin.macro"

import slide1 from '../images/slides/slide-1.jpg'
import slide2 from '../images/slides/slide-2.jpg'
import slide3 from '../images/slides/slide-3.jpg'
import slide4 from '../images/slides/slide-4.jpg'
import slide5 from '../images/slides/slide-5.jpg'


const IntroBlockWrap = styled.div`
  ${tw`text-center font-basker max-w-screen-xl mx-auto`}
`

const CaptionWrap = styled.div`
  ${tw`text-left font-basker text-apana-plum py-7 flex justify-center flex-col height[90px]`}
  h2 { ${tw`m-0 p-0 pb-2 font-normal italic text-2xl md:text-xl mx-auto`} }
  p { ${tw`text-base md:text-sm mx-auto`} }
  div { ${tw`px-3`} }
`

const Container = styled.div`
  img {
    ${tw`w-full object-cover aspect-ratio[3/1]`}
  }

  @supports not (aspect-ratio: 3/1) {
    img::before {
      ${tw`float-left content padding-top[67%]`}
    }
    img::after {
      ${tw`block content clear-both`}
    }
  }
`

const Slides = [
  ['Move with You','Materials made to retain their shape', slide1],
  ['Set your Intention','Bind intention with balance', slide3],
  ['Superior Function','Breathable Comfort, Supportive Fit', slide4],
  ['Approach Challenges','Smooth seams and supportive waistband', slide2],
  ['Reflect, inside & out','Support & Comfort where you need it', slide5]
]

const ImageBlock = ({index}) => { 

  return (
    <IntroBlockWrap>
      <Container key={index} className={`contain-${index}`}>
        <img src={Slides[index][2]} alt={Slides[index][0]} />
        <CaptionWrap>
          <div>
            <h2>{Slides[index][0]}</h2>
            <p>{Slides[index][1]}</p>
          </div>
        </CaptionWrap>
      </Container>
    </IntroBlockWrap>
  )
}

export default ImageBlock
