import React from "react"
import tw, { styled } from "twin.macro"


const ContentBlockStyle = styled.div`
  ${tw`px-3 py-6 font-basker text-apana-plum max-w-screen-xl m-auto`}
  h1 { ${tw`text-5xl pb-3`} }
  h2 { ${tw`text-4xl pb-2 leading-snug`} }
  h3 { ${tw`text-3xl pb-2`} }
  h4 { ${tw`font-sans font-medium tracking-wider uppercase`} }

`

const ContentBlock = (props) => (
  <ContentBlockStyle>
    { props.children }
  </ContentBlockStyle>
)

export default ContentBlock
