import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ImageBlock from "../components/imageBlock"
// import SignupBlock from "../components/signup"
import InstaBlock from "../components/insta"
import ContentBlock from "../components/contentBlock"
import ContentBlockHeader from "../components/contentBlockHeader"
import ProductBlock from "../components/productStrip"


const IndexPage = () =>
  <Layout>

    <SEO title="Home" />

    <ContentBlockHeader>
      {/* <SignupBlock /> */}
    </ContentBlockHeader>

    <ImageBlock index="1" />

    <ContentBlock>
      <h3>Without flashy branding, or ugly fast fashion colors, Apana is focused solely on our core mission: produce the highest quality functional yoga apparel for an affordable price.</h3>
    </ContentBlock>

    <ProductBlock />

    <ContentBlock>
      <h2>Compare our product and prices to any of the famous yoga brands. We focus on product quality over marketing a lifestyle, and we truly believe our clothes reflect this.</h2>
      <h4>Try Apana Yoga and never overpay for yoga apparel again</h4>
    </ContentBlock>

    <ImageBlock index="2" />

    <ImageBlock index="3" />


    <InstaBlock />

  </Layout>

export default IndexPage
